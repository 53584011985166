<template>
  <b-row>

    <h2 class="pl-1">FAQ</h2>

    <!--  BEFORE TABLE  -->
    <div class="d-flex justify-content-between col-12">
      <b-col
          md="4"
          class="my-1 px-0"
      >
        <b-form-group
            class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Искать"
            />
            <b-input-group-append>
              <b-button
                  :disabled="!filter"
                  @click="filter = ''"
              >
                Очистить
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <!--   CREATE   -->
      <div
          class="my-1 float-right"
      >
        <modal-button
            ripple="rgba(113, 102, 240, 0.15)"
            size="xl"
            name="modal-main"
            modal-title="Создание"
            modalButton="Создать"
            @btn-emit="createFaq"
            @open-emit="clearInputs"
            variant="success"
            ref="create-modal-button"
        >
          <template v-slot:button @click="createFaq">Создать</template>

          <template
              v-slot:modal-body
              class="p-5"
          >
            <ValidationObserver ref="validation-observer">
              <div class="d-flex flex-wrap">

                <!--   QUESTION [RU]   -->
                <b-col cols="6">
                  <ValidationProvider :name="`Вопрос [RU]`" rules="required" v-slot="{errors}">
                    <b-form-group
                        label="Вопрос [RU]"
                        label-for="question_ru"
                    >
                      <b-form-textarea
                          rows="4"
                          v-model="question.ru"
                          id="question_ru"
                          size="lg"
                          placeholder="Вопрос [RU]"
                      />
                    </b-form-group>
                    <span class="validation__red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>

                <!--   ANSWER [RU]   -->
                <b-col cols="6">
                  <ValidationProvider :name="`Ответ [RU]`" rules="required|max:400" v-slot="{errors}">
                    <b-form-group
                        label="Ответ [RU]"
                        label-for="answer_ru"
                    >
                      <b-form-textarea
                          rows="4"
                          v-model="answer.ru"
                          id="answer_ru"
                          size="lg"
                          placeholder="Ответ [RU]"
                      />
                    </b-form-group>
                    <span class="validation__red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>

                <!--   QUESTION [UZ]   -->
                <b-col cols="6">
                  <ValidationProvider :name="`Вопрос [UZ]`" rules="required" v-slot="{errors}">
                    <b-form-group
                        label="Вопрос [UZ]"
                        label-for="question_uz"
                    >
                      <b-form-textarea
                          rows="4"
                          v-model="question.uz"
                          id="question_uz"
                          size="lg"
                          placeholder="Вопрос [UZ]"
                      />
                    </b-form-group>
                    <span class="validation__red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>

                <!--   ANSWER [UZ]   -->
                <b-col cols="6">
                  <ValidationProvider :name="`Ответ [UZ]`" rules="required|max:400" v-slot="{errors}">
                    <b-form-group
                        label="Ответ [UZ]"
                        label-for="answer_uz"
                    >
                      <b-form-textarea
                          rows="4"
                          v-model="answer.uz"
                          id="answer_uz"
                          size="lg"
                          placeholder="Ответ [UZ]"
                      />
                    </b-form-group>
                    <span class="validation__red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>

                <!--    URL [RU]    -->
                <b-col cols="6">
                  <ValidationProvider :name="`Ссылка [RU]`" rules="regex:https" v-slot="{errors}">
                    <b-form-group
                        label="Ссылка [RU]"
                        label-for="url_ru"
                    >
                      <b-form-input
                          v-model="url.ru"
                          id="url_ru"
                          size="lg"
                          placeholder="Ссылка [https]"
                      />
                    </b-form-group>
                    <span class="validation__red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>

                <!--    URL [UZ]    -->
                <b-col cols="6">
                  <ValidationProvider :name="`Ссылка [UZ]`" rules="regex:https" v-slot="{errors}">
                    <b-form-group
                        label="Ссылка [UZ]"
                        label-for="url_uz"
                    >
                      <b-form-input
                          v-model="url.uz"
                          id="url_uz"
                          size="lg"
                          placeholder="Ссылка [https]"
                      />
                    </b-form-group>
                    <span class="validation__red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>

                <!--   FAQ CATEGORIES   -->
                <b-col cols="6" class="pb-3">
                  <ValidationProvider
                      :name="$t('validation_errors.only_regions')"
                      rules="required"
                      v-slot="{errors}"
                  >
                    <label for="region_id">FAQ Категории</label>
                    <v-select
                        v-model="selected"
                        label="title"
                        :options="option"
                        class="select-size-lg"
                        id="faq-category"
                    />
                    <span class="validation__red mt-1">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>

                <!--   IMAGE   -->
                <b-col cols="6">
                  <ValidationProvider name="Изображения" rules="size:2048" v-slot="{errors}">
                    <b-form-group
                        label="Изображения (Максимальный размер - 2МБ)"
                        label-for="image"
                    >
                      <b-form-file
                          v-model="image"
                          id="image"
                          size="lg"
                          placeholder="Изображения"
                      />
                    </b-form-group>
                    <span class="validation__red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>


              </div>
            </ValidationObserver>
          </template>
        </modal-button>

      </div>
    </div>

    <!--  TABLE  -->
    <b-col cols="12">
      <b-table
          striped
          hover
          responsive
          sort-icon-left
          :busy="isBusy"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
      >

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-1">Идет загрузка ...</strong>
          </div>
        </template>

        <template #cell(question)="data">
          {{ data.item.question.ru }}
        </template>

        <template #cell(answer)="data">
          <span class="text__wrap">{{ data.item.answer.ru }}</span>
        </template>

        <template #cell(url)="data">
          <span v-if="data.item.url && data.item.url.ru !== 'null'">{{ data.item.url.ru }}</span>
        </template>

        <template #cell(image)="data">
          <span v-if="data.item.image" class="c-img">
             <img :src="`${baseUrl}/${data.item.image}`" alt="">
          </span>
        </template>

        <template #cell(faq_category)="data">
          <span>
            {{ data.item.faq_category.title.ru }}
          </span>
        </template>

        <template #cell(crud_row)="data">
          <div class="d-flex float-right">

            <!--    EDIT    -->
            <modal-button
                class="crud__btn"
                ripple="rgba(113, 102, 240, 0.15)"
                size="xl"
                @btn-emit="updateFaq(data.item.id, data.item)"
                @open-emit="getOneFaq(data.item.id)"
                :name="`modal-edit-${data.item.id}`"
                modalButton="Сохранить"
                :id="data.item.id"
                modal-title="Редактировать"
                variant="flat-warning"
                ref="update-modal-button"
            >
              <template
                  v-slot:button
              >
                <feather-icon
                    icon="Edit2Icon"
                    size="21"
                />
              </template>

              <template v-slot:modal-body>
                <ValidationObserver ref="validation-observer">
                  <div class="d-flex flex-wrap">

                    <!--   QUESTION [RU]   -->
                    <b-col cols="6">
                      <ValidationProvider :name="`Вопрос [RU]`" rules="required" v-slot="{errors}">
                        <b-form-group
                            label="Вопрос [RU]"
                            label-for="question_ru"
                        >
                          <b-form-textarea
                              rows="4"
                              v-model="question.ru"
                              id="question_ru"
                              size="lg"
                              placeholder="Вопрос [RU]"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--   ANSWER [RU]   -->
                    <b-col cols="6">
                      <ValidationProvider :name="`Ответ [RU]`" rules="required|max:400" v-slot="{errors}">
                        <b-form-group
                            label="Ответ [RU]"
                            label-for="answer_ru"
                        >
                          <b-form-textarea
                              rows="4"
                              v-model="answer.ru"
                              id="answer_ru"
                              size="lg"
                              placeholder="Ответ [RU]"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--   QUESTION [UZ]   -->
                    <b-col cols="6">
                      <ValidationProvider :name="`Вопрос [UZ]`" rules="required" v-slot="{errors}">
                        <b-form-group
                            label="Вопрос [UZ]"
                            label-for="question_uz"
                        >
                          <b-form-textarea
                              rows="4"
                              v-model="question.uz"
                              id="question_uz"
                              size="lg"
                              placeholder="Вопрос [UZ]"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--   ANSWER [UZ]   -->
                    <b-col cols="6">
                      <ValidationProvider :name="`Ответ [UZ]`" rules="required|max:400" v-slot="{errors}">
                        <b-form-group
                            label="Ответ [UZ]"
                            label-for="answer_uz"
                        >
                          <b-form-textarea
                              rows="4"
                              v-model="answer.uz"
                              id="answer_uz"
                              size="lg"
                              placeholder="Ответ [UZ]"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--    URL [RU]    -->
                    <b-col cols="6">
                      <ValidationProvider :name="`Ссылка [RU]`" rules="regex:https" v-slot="{errors}">
                        <b-form-group
                            label="Ссылка [RU]"
                            label-for="url_ru"
                        >
                          <b-form-input
                              v-model="url.ru"
                              id="url_ru"
                              size="lg"
                              placeholder="Ссылка [https]"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--    URL [UZ]    -->
                    <b-col cols="6">
                      <ValidationProvider :name="`Ссылка [UZ]`" rules="regex:https" v-slot="{errors}">
                        <b-form-group
                            label="Ссылка [UZ]"
                            label-for="url_uz"
                        >
                          <b-form-input
                              v-model="url.uz"
                              id="url_uz"
                              size="lg"
                              placeholder="Ссылка [https]"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--   FAQ CATEGORIES   -->
                    <b-col cols="6" class="pb-3">
                      <ValidationProvider
                          :name="$t('validation_errors.only_regions')"
                          rules="required"
                          v-slot="{errors}"
                      >
                        <label for="region_id">FAQ Категории</label>
                        <v-select
                            v-model="selected"
                            label="title"
                            :options="option"
                            class="select-size-lg"
                            id="faq-сategory"
                        />
                        <span class="validation__red mt-1">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <div class="col-6 px-0">

                      <!--   IMAGE   -->
                      <b-col
                          cols="12"
                          v-if="!image || typeof image !== 'string'"
                      >
                        <ValidationProvider name="Изображения" rules="size:2048" v-slot="{errors}">
                          <b-form-group
                              label="Изображения (Максимальный размер - 2МБ)"
                              label-for="image"
                          >
                            <b-form-file
                                v-model="image"
                                id="image"
                                size="lg"
                                placeholder="Изображения"
                            />
                          </b-form-group>
                          <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                          class="my-2"
                          cols="12"
                          v-else
                      >
                        <div
                            class="c-img delete_btn"
                            @click="deleteImage()"
                        >
                          <img :src="image" alt="">
                        </div>
                      </b-col>
                    </div>

                  </div>
                </ValidationObserver>
              </template>

            </modal-button>

            <!--   DELETE   -->
            <modal-button
                class="crud__btn"
                ripple="rgba(113, 102, 240, 0.15)"
                size="sm"
                :name="`modal-delete-${data.item.id}`"
                modalButton="Удалить"
                modal-title="Удалить"
                variant="flat-danger"
                :id="data.item.id"
                ref="delete-modal"
                @btn-emit="deleteFaq"
            >
              <template
                  v-slot:button
              >
                <feather-icon
                    icon="Trash2Icon"
                    size="21"
                />
              </template>

              <template v-slot:modal-body>
                <div class="my-2">
                  Вы действительно хотите удалить этот FAQ?
                </div>
              </template>

            </modal-button>

          </div>
        </template>
      </b-table>
    </b-col>

    <!--  PAGINATION  -->
    <b-col
        cols="12"
    >
      <b-pagination
          v-if="showPagination"
          v-model="pagination.current"
          :total-rows="pagination.total"
          :per-page="pagination.per_page"
          align="center"
          size="sm"
          class="my-0"
      />
    </b-col>

  </b-row>
</template>

<script>
import {
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BSpinner,
  BCard,
  BFormTextarea,
  BFormFile
} from 'bootstrap-vue'
import ModalButton from "@/views/ui/modals/ModalButton";
import api from '@/services/api'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss';

export default {
  name: "Faqs",
  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BSpinner,
    BCard,
    BFormTextarea,
    BFormFile,
    ModalButton,
    ToastificationContent,
    vSelect
  },
  directives: {
    Ripple
  },
  data() {
    return {
      question: {
        en: '',
        ru: '',
        uz: '',
      },
      answer: {
        en: '',
        ru: '',
        uz: '',
      },
      url: {
        ru: '',
        uz: ''
      },
      image: null,
      faq_category: null,
      selected: {},
      option: [],
      baseUrl: process.env.VUE_APP_URL,
      position: null,
      isBusy: false,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'faq_category',
          label: 'Категории',
        },
        {
          key: 'question',
          label: 'Вопрос',
        },
        {
          key: 'answer',
          label: 'Ответ',
        },
        {
          key: 'url',
          label: 'Ссылка',
        },
        {
          key: 'image',
          label: 'Изображения',
        },
        {
          key: 'crud_row',
          label: ' ',
        },
      ],
      items: [],
      pagination: {
        current: 1,
        total: 5,
        per_page: 5
      }
    }
  },
  watch: {
    '$route.query': {
      handler(query) {
        // const routeQueryPage = parseInt(query.page)
        this.pagination.current = query.page
      },
      deep: true,
      immediate: true
    },
    'pagination.current': {
      handler(page) {
        this.replaceRouter({
          ...this.query,
          page,
        })
      },
    }
  },

  async created() {
    await this.getFaqs()
    await this.getFaqCategoriesForSelect()
    // await this.getSelectFaqCategory()
  },

  computed: {
    rows() {
      return this.items.length
    },

    query() {
      return Object.assign({}, this.$route.query)
    },

    hasItems() {
      return this.items.length > 0
    },

    showPagination() {
      return this.hasItems && !this.isBusy
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({
            text: f.label,
            value: f.key,
          }))
    },
  },

  methods: {

    deleteImage() {
      this.image = null
    },

    getSelectFaqCategory(options, item) {
      const currentOption = options.find(option => option.id === item.faq_category)
      if (currentOption) {
        return currentOption.title
      }
      return ''
    },

    showToast(variant, text, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: icon,
          variant,
        },
      })
    },

    replaceRouter(query) {
      this.$router.replace({query})
          .catch(() => {
          })
    },

    clearInputs() {
      this.question.en = ''
      this.question.ru = ''
      this.question.uz = ''
      this.answer.en = ''
      this.answer.ru = ''
      this.answer.uz = ''
      this.position = ''
      this.selected = null
      this.image = null
      this.url.ru = ''
      this.url.uz = ''
    },

    async getFaqs() {
      this.isBusy = true
      await api.faqs.fetchFaqs()
          .then(res => {
            this.items = res.data
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.isBusy = false
          })
    },

    getFaqCategoriesForSelect() {
      api.faqCategories.fetchFaqCategories()
          .then(res => {
            this.option = res.data.map(item => {
              return {
                title: item.title.ru,
                id: item.id,
              }
            })
          })
          .catch((error) => {
            console.error(error)
          })
    },

    async createFaq() {
      const isValid = await this.$refs['validation-observer'].validate()
      if (isValid) {

        const formData = new FormData()
        formData.append('question[ru]', this.question.ru)
        formData.append('answer[ru]', this.answer.ru)
        formData.append('question[uz]', this.question.uz)
        formData.append('answer[uz]', this.answer.uz)
        formData.append('faq_category_id', this.selected.id)
        formData.append('url[ru]', this.url.ru)
        formData.append('url[uz]', this.url.uz)
        formData.append('image', this.image)

        api.faqs.createNewFaq(formData)
            .then(res => {
              this.$refs['create-modal-button'].closeModal()
              this.showToast('success', 'Успешно создано!', 'CheckIcon')
            })
            .catch((error) => {
              console.error(error)
              this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
            })
            .finally(() => {
              this.getFaqs()
              this.clearInputs()
            })
      }
    },

    async getOneFaq(id) {
      await api.faqs.fetchOneFaq(id)
          .then(res => {
            this.question.ru = res.data.question.ru
            this.answer.ru = res.data.answer.ru
            this.question.uz = res.data.question.uz
            this.answer.uz = res.data.answer.uz
            if (res.data.image) {
              this.image = `${this.baseUrl}/${res.data.image}`
            } else {
              this.image = null
            }
            this.selected = {
              id: res.data.faq_category.id,
              title: res.data.faq_category.title['ru']
            }
            this.url.ru = res.data.url.ru === 'null' ? '' : res.data.url.ru
            this.url.uz = res.data.url.uz === 'null' ? '' : res.data.url.uz
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
          })
    },

    async updateFaq(id) {
      const isValid = await this.$refs['validation-observer'].validate()
      if (isValid) {
        const formData = new FormData()
        formData.append('question[ru]', this.question.ru)
        formData.append('answer[ru]', this.answer.ru)
        formData.append('question[uz]', this.question.uz)
        formData.append('answer[uz]', this.answer.uz)
        formData.append('faq_category_id', this.selected.id)
        formData.append('url[ru]', this.url.ru)
        formData.append('url[uz]', this.url.uz)
        formData.append('image', this.image)

        api.faqs.updateFaq(id, formData)
            .then(res => {
              this.showToast('success', 'Успешно обновлено!', 'CheckIcon')
            })
            .catch((error) => {
              console.error(error)
              this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
            })
            .finally(() => {
              this.$refs['update-modal-button'].closeModal()
              this.getFaqs()
              this.clearInputs()
            })
      }
    },

    deleteFaq(id) {
      api.faqs.deleteFaq(id)
          .then(res => {
            this.$refs["delete-modal"].closeModal()
            this.showToast('success', 'Успешно удалено!', 'CheckIcon')
          })
          .catch((error) => {
            console.error(error)
            this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
          })
          .finally(() => {
            this.getFaqs()
          })
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },

    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of modals/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss" scoped>

.text__wrap {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

[dir] .dropdown-item {
  padding: 0;
}

.delete_btn {
  position: relative;

  &::after {
    content: 'X';
    width: 16px;
    height: 16px;
    position: absolute;
    top: -8px;
    right: -8px;
    font-size: 10px;
    cursor: pointer;
    border: 1px solid #fff;
    color: #000000;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.c-img {
  max-width: 60px;
  max-height: 60px;

  & img {
    width: 100%;
    max-width: 60px;
    object-fit: contain;
  }
}

.validation__red {
  color: red;
  font-size: 12px;
  display: block;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 6px;
}

.crud__btn .btn {
  padding: 0.6rem;
}

</style>
